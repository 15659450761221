<template>
  <div>
    <!-- 顶部 -->
    <div class="title_div">盘点</div>
    <!-- itemsList -->
    <div
    class="my"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 3vh;
        padding: 7vw 0;
      "
     
    >
      <div
        v-for="(item, index) in itemsList"
        :key="index"
        class="order_item"
        style="width: 23vw; font-size: 2vw; margin-top: -3vw"
        @click="stock_talking(index)"
      >
        <img :src="item.img" style="height: 11.6vw" />
        <div style="margin-top: 1vh; margin-bottom: 3vh;font-size:4vw;font-weight: bolder;">{{ item.name }}</div>
      </div>
    </div>
    <!-- 商品助手 -->
    <div
    class="my1"
    @click="helper()"

      style="display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      
        padding: 5vw 0;
      " >
     
      <div style="display:flex;align-items: center;margin-right:22vw;">
        <div class="help" style="display:flex;justify-content: center;align-items: center;" ><img src="../assets/icon/矢量智能对象.png" alt="" style="width:9vw;height:9vw"></div>
       <div style="font-size:4.6vw;margin-left: 4vw;">营业助手</div>
      </div>

      <div style="margin-left:6vw">
        <img src="../assets/icon/31fanhui1.png" alt="" style="width:2vw;height:3.5vw">
      </div>
  
    </div>
    <!--底部tab栏-->
    <Tabbar
      :choose_index="2"
      :user_type2="user_type2"
      :type="pagetype"
    ></Tabbar>
  </div>
</template>
  <script>
import Tabbar from "@/components/Tabbar";
export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      type:0,
      pointList:[],
      user_type2: false, //是否是区域看板
      pagetype: 0, //1总部看板
      itemsList: [
        {
          img: require("@/assets/tabbar/quanpan.png"),
          name: "总部全盘",
        },
        {
          img: require("@/assets/tabbar/dongxiaopan.png"),
          name: "门店全盘",
        },
        {
          img: require("@/assets/tabbar/choupan.png"),
          name: "动销盘",
        },
        {
          img: require("@/assets/tabbar/dongtaichoupan.png"),
          name: "总部抽盘",
        },
        {
          img: require("@/assets/tabbar/jishipandian.png"),
          name: "动态抽盘",
        },
        {
          img: require("@/assets/tabbar/dongtaichoupan.png"),
          name: "即时盘点",
        },
        {
          img: "",
          name: "",
        },
        {
          img: "",
          name: "",
        },
       
      ],
     
    };
  },
  created() {
  this.inventoryList()

  },
  methods: {
  // 商品助手跳转到搜索信息页面
  helper(){
    this.$router.push("./helper")
  },
  //  盘点页面 分别跳转
    stock_talking(index,path){
    console.log(index,"ass")
    // console.log(path,"aaaaaaaaa")
    this.inventoryList(index)
    this.$router.push(`./point_style/${index}`)
  },
   //inventoryList
   inventoryList(ind){
      this.$axios.get(this.$api.inventoryList,{
        params:{
          type:ind,
          limit:this.page.pageSize,
          page:this.page.currentPage,
        }
      }).then(res=>{
        this.pointList=res.data.page.list
        console.log(this.pointList,"inventoryList")
        this.pointList =res.data.page.list
        
      })
    },
  
  }
};
</script>
  <style scoped>
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  line-height: 12vh;
  color: #ffffff;
  text-align: center;
  font-size: 4.6vw;
}
.every_option {
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
}
.search_input {
  outline: none;
  border: none;
  width: 40vw;
  border-bottom: 1px solid #e7e6e6;
  font-size: 14px;
  padding-left: 10px;
}
.my {
  width: 94vw;
  height: 50vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/2.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  padding: 5vw 0;
 
}
.my1{
  width: 94vw;
  height: 7vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/2.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  padding: 5vw 0;
}
.order_item {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  
}
.help{
  background: url(../assets/icon/矩形\ 1.png) no-repeat;
  width: 12vw;
  height: 12vw;
  background-size: 100% 100%;
}
</style>